import React from 'react';
import Footer from '../../components/Footer';

const index = () => {
  return <>
  <div>Errro Page 404 Not found</div>;
  <Footer/>
  </>
};

export default index;
